import React from "react"
import Config from "./config";
import FIP from "./models/fip";
import { Slide, FadeOut, FadeIn, SlideOutRight } from 'react-animated-components'
import FIPTileInner from "./fip-tile-inner";
import { FIPMessageTypes } from "./messages/fip-messages";

interface IFIPTileProps {
      fip: FIP;
      currentWork: any
}

const FIPTile = (props: IFIPTileProps) => {
 

      return (
            <>

            {props.fip.workState === FIPMessageTypes.FRAME_START.valueOf()  && 
                  <Slide active={props.fip.workState === FIPMessageTypes.FRAME_START.valueOf()} iterations={1}  durationMs={4000}  fade={true} direction={ "down"}>
                        <FIPTileInner key={ props.fip.serialId} fip={props.fip} currentWork={null} />               
                  </Slide>
            } 
            {props.fip.workState === FIPMessageTypes.FRAME_REJECTED_HERE.valueOf() && 
                  <Slide active={props.fip.workState === FIPMessageTypes.FRAME_REJECTED_HERE.valueOf()} iterations={1}  durationMs={4000}  fade={true} direction={ "down"}>
                        <FIPTileInner key={ props.fip.serialId} fip={props.fip} currentWork={null} />               
                  </Slide>
            }    
            { ( props.fip.workState === FIPMessageTypes.FRAME_END.valueOf()   || props.fip.workState === FIPMessageTypes.FRAME_REJECTED.valueOf() || props.fip.workState === FIPMessageTypes.FRAME_VOIDED.valueOf() ) && 

                  <SlideOutRight durationMs={4000} fade={true} iterations={1} direction={ "right"}>
                        <FIPTileInner key={ props.fip.serialId} fip={props.fip} currentWork={null} />               
                  </SlideOutRight>
            } 

            { ( props.fip.workState === FIPMessageTypes.FRAME_VOIDED.valueOf() ) && 

                  <FadeOut durationMs={4000} iterations={1} >
                        <FIPTileInner key={ props.fip.serialId} fip={props.fip} currentWork={null} />               
                  </FadeOut>
            } 
            { (  props.fip.workState === FIPMessageTypes.FRAME_UNVOIDED.valueOf() ) && 

                  <FadeIn durationMs={4000} iterations={1} direction={ "right"}>
                        <FIPTileInner key={ props.fip.serialId} fip={props.fip} currentWork={null} />               
                  </FadeIn>
            } 

             { !props.fip.workState  &&        
                  <FIPTileInner key={ props.fip.serialId} fip={props.fip} currentWork={null} />               
            } 
            </>
      );



}

export default FIPTile;
import FIP from "../models/fip";

export enum FIPMessageTypes {
      /// these will likely be a subset of the frame history events? should use those instead?
      FRAME_START = "STARTED",
      FRAME_END = "COMPLETED",
      FRAME_VOIDED = "VOIDED",
      FRAME_UNVOIDED = "UNVOIDED",
      FRAME_REJECTED = "REJECTED",
      FRAME_REJECTED_HERE = "REJECTED HERE" // this is client side only
}

export class FIPMessageBase {
      messageType: string
      constructor( messageType: FIPMessageTypes) {
            this.messageType = messageType.valueOf()
      }

}

// most of these will be triggered by a scanner event -- 
// listing out here
//  frame started at station - 
//  frame ended at station - moving to next / other station
//  frame rejected at station - moveing to another station
//  frame voided
//  frame unvoided
//   .. label printing.. not applicable
//  .. history lookups .. no applicable
// .. frame destroyed what else??
export class FIPMessage extends FIPMessageBase {
      constructor( messageType : FIPMessageTypes, frameSerialId: string, curStation: number, nextStation?: number, wasVoided?: boolean, wasUnvoided?: boolean, isReturn?: boolean, fip?: FIP ) {
            super( messageType )
            this.frameSerialId = frameSerialId;
            this.curStation = curStation;
            this.nextStation = nextStation;
            this.wasVoided = wasVoided;
            this.wasUnvoide = wasUnvoided;
            this.isReturn = isReturn;
            this.fip = fip;
      }

      curStation: number; //where the frame currently is
      nextStation?: number;  // where it is being moved to if applicable
      frameSerialId: string;
      // potential state changes
      wasVoided?: boolean;
      wasUnvoide?: boolean;
      isReturn?: boolean; //??
      fip?: FIP;

    

}
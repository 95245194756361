import React, { useState, useEffect } from 'react';
import './App.css';
import axios from 'axios'
import FIP from './models/fip';
import { StationsEnum } from './station-enums';
import { IStationColumn } from './models/station-column';
import StationColumn from './station-column';
import { io, ManagerOptions, SocketOptions } from "socket.io-client";
import { FrameTypesEnum } from './models/frame-types-enum';
import ActiveBatches from './active-batch';
import { ActiveBatch } from './models/active-batches/active-batch';
import { SOCKET_CHANNELS } from './channels/channels';
import { BatchState, CuttingStationBatchItemMessage, CuttingStationBatchMessage, MessageBase, MessageTypes } from './messages/cutting-messages';
import { BatchItem } from './models/active-batches/batch-item';
import { FIPMessage, FIPMessageTypes } from './messages/fip-messages';
import { cursorTo } from 'readline';


const ip = "192.168.0.3"
//const socket = io("ws://" + ip + ":3001/fip");
//const socket = io("ws://" + ip + ":3001/wss_gateway");
const connectionOptions : Partial<ManagerOptions & SocketOptions> = {
      reconnection: true,
      /**
       * How many reconnection attempts should we try?
       * @default Infinity
       */
 //     reconnectionAttempts: number; 
       /**
       * The time delay in milliseconds between reconnection attempts
       * @default 1000
       */
      reconnectionDelay: 5000,
      /**
       * The max time delay in milliseconds between reconnection attempts
       * @default 5000
       */
//      reconnectionDelayMax: number;
      /**
       * Used in the exponential backoff jitter when reconnecting
       * @default 0.5
       */
  //    randomizationFactor: number;
      /**
       * The timeout in milliseconds for our connection attempt
       * @default 20000
       */
//      timeout: number;
      /**
       * Should we automatically connect?
       * @default true
       */
//      autoConnect: boolean;
      /**
       * the parser to use. Defaults to an instance of the Parser that ships with socket.io.
       */
  //    parser: any;
};


const socket = io('http://' + ip + ':3000', connectionOptions);

let frameType: FrameTypesEnum = FrameTypesEnum.premiere;

function App() {

      const [isConnected, setIsConnected] = useState(socket.connected);
      const [lastPong, setLastPong] = useState<string>("");
      const [activeBatches, setActiveBatches] = React.useState<ActiveBatch[]>([])

      const [cutting, setCutting] = React.useState<FIP[]>([])
      const [boring, setBoring] = React.useState<FIP[]>([])
      const [coloring, setColoring] = React.useState<FIP[]>([])
      const [pinsAndMagnets, setPingsAndMagnets] = React.useState<FIP[]>([])
      const [qc, setQC] = React.useState<FIP[]>([])
      const [boxing, setBoxing] = React.useState<FIP[]>([])
      const [shipping, setShipping] = React.useState<FIP[]>([])

      const [milling, setMilling] = React.useState<FIP[]>([])
      const [finalPrep, setFinalPrep] = React.useState<FIP[]>([])


      const stationColumns: IStationColumn[] = [
            { title: "Cutting", id: "cutting", frames: [], frameTypes: [FrameTypesEnum.alloy, FrameTypesEnum.premiere] },
            { title: "Boring", id: "boring", frames: [], frameTypes: [FrameTypesEnum.premiere] },
            { title: "Coloring", id: "coloring", frames: [], frameTypes: [FrameTypesEnum.premiere] },
            { title: "Pins & Magnets", id: "pins-magnets", frames: [], frameTypes: [FrameTypesEnum.premiere] },
            { title: "QC", id: "quality-control", frames: [], frameTypes: [FrameTypesEnum.premiere] },
            { title: "Boxing", id: "boxing", frames: [], frameTypes: [FrameTypesEnum.alloy, FrameTypesEnum.premiere] },
            { title: "Tickets to Ship", id: "shipping", frames: [], frameTypes: [FrameTypesEnum.premiere] },

            { title: "Milling", id: "milling", frames: [], frameTypes: [FrameTypesEnum.premiere] },
            { title: "Final Prep", id: "finalprep", frames: [], frameTypes: [FrameTypesEnum.premiere] },

      ];

      const getColumnStore = (curStation: number) => {
            let column = null;
            switch (curStation) {
                  case StationsEnum.cutting:
                        column = cutting
                        break;
                  case StationsEnum.boring:
                        column = boring
                        break;
                  case StationsEnum.coloring:
                        column = coloring
                        break;
                  case StationsEnum.pinsAndMagnets:
                        column = pinsAndMagnets
                        break;
                  case StationsEnum.qualityControl:
                        column = qc
                        break;
                  case StationsEnum.boxing:
                        column = boxing
                        break;
                  case StationsEnum.shipping:
                        column = shipping
                        break;
                  //                        case StationsEnum.premiereAwaitingShipping:
                  //                              column = awaitingShipsetShipping
                  //                              break;       
                  case StationsEnum.milling:
                        column = milling
                        break;
                  case StationsEnum.finalPrep:
                        column = finalPrep
                        break;
                  default:
                  // code block
            }

            return column;
      }
      const getColumnHook = (curStation: number) => {
            let setColumnHook = null;
            switch (curStation) {
                  case StationsEnum.cutting:
                        setColumnHook = setCutting
                        break;
                  case StationsEnum.boring:
                        setColumnHook = setBoring
                        break;
                  case StationsEnum.coloring:
                        setColumnHook = setColoring
                        break;
                  case StationsEnum.pinsAndMagnets:
                        setColumnHook = setPingsAndMagnets
                        break;
                  case StationsEnum.qualityControl:
                        setColumnHook = setQC
                        break;
                  case StationsEnum.boxing:
                        setColumnHook = setBoxing
                        break;
                  case StationsEnum.shipping:
                        setColumnHook = setShipping
                        break;
                  case StationsEnum.premiereAwaitingShipping:
                        setColumnHook = setShipping
                        break;
                  case StationsEnum.milling:
                        setColumnHook = setMilling
                        break;
                  case StationsEnum.finalPrep:
                        setColumnHook = setFinalPrep
                        break;
                  default:
                  // code block
            }

            return setColumnHook;
      }

      const findFrameInAnyStation = (curStation: number, frameSerialId: string, workState?: string ): { stationId: number, store: FIP[], setHook: React.Dispatch<React.SetStateAction<FIP[]>> } | null => {
            // the frame should be in the curStation where it was scanned but it maybe out of order

            let foundFrame = null;
            let columnStore = getColumnStore(curStation);
            const premiereStores = [{ stationId: StationsEnum.cutting, store: cutting, setHook: setCutting },
            { stationId: StationsEnum.boring, store: boring, setHook: setBoring },
            { stationId: StationsEnum.coloring, store: coloring, setHook: setColoring },
            { stationId: StationsEnum.pinsAndMagnets, store: pinsAndMagnets, setHook: setPingsAndMagnets },
            { stationId: StationsEnum.qualityControl, store: qc, setHook: setQC },
            { stationId: StationsEnum.boxing, store: boxing, setHook: setBoxing },
            { stationId: StationsEnum.shipping, store: shipping, setHook: setShipping },
            ];

            let foundAtCurrentStation: FIP | undefined;

            // look in the current station first since it most likely is there (fast case)
            foundAtCurrentStation = premiereStores.find(f => f.stationId === curStation)?.store.find(f => f.serialId == frameSerialId);

            let found = null;
            if (foundAtCurrentStation) {
                  return premiereStores.find(f => f.stationId === curStation)!;
            } else {
                  found = premiereStores.find(store => store.store.find(f => f.serialId === frameSerialId) != undefined)
            }
            if ( found ) {
                  return found;
            } else {
                  return null;
            }
      }

      useEffect(() => {

            // used for a completed frame, including when it is rejected or voided 
            // when ending work, the frame should be in the curStation already (moved there from the start work).. however if a start work event
            // was lost it could still be elsehwere and need removing/adding 
            // - to animate, want the frame 'just scanned' to show 'COMPLETED' then go off the screen.
            // - the frame should also appear in its new column - perhaps with a 'processed today' or some other state, although sorting should pop it
            const endWorkOnFrame = (curStation: number, nextStation: number, frameSerialId: string, messageType: FIPMessageTypes) => {

                  // most likely the frame is in the current station's column so try that first to be fast
                  let setCurColumnHook = getColumnHook(curStation);
                  let frameToFind: FIP | undefined;

                  if (setCurColumnHook) {

                        // remove the frame from whatever station column it is currently in 
                        // and move it to its destination column (if there is one - it could be completed)
                        setCurColumnHook(frames => {
                              // remove the frame from the column
                              frameToFind = frames.find( f => f.serialId === frameSerialId );                           
                              let otherFrames = frames.filter(frame => frame.serialId !== frameSerialId)
                              if ( frameToFind ) {
                                    // frame is in the column already
                                   

                                  
                                    // move the frame to the column it should go to after the work is complete
                                    let moveToColumnHook = getColumnHook(nextStation);
                                    if ( moveToColumnHook ) {
                                          if ( messageType === FIPMessageTypes.FRAME_REJECTED) {
                                                let copyFrame = JSON.parse(JSON.stringify(frameToFind!));
                                                copyFrame.workState = FIPMessageTypes.FRAME_REJECTED_HERE;
                                                moveToColumnHook( otherFrames => {
                                                      return [...otherFrames, copyFrame ].sort(sortFrameColumn);
                                                })
                                          } else {
                                                moveToColumnHook( framesInDestination => {
                                                      return [...framesInDestination, frameToFind! ].sort(sortFrameColumn);
                                                })
                                          }
                                         
                                    }

                                    frameToFind.id = frameToFind.id + "."; // change id so it is distinct for react - it will be remove anyhow
                                    frameToFind!.workState =  messageType.valueOf();
                                   
                                    return [...otherFrames, frameToFind].sort(sortFrameColumn);
                              } else {
                                    // frame is not in the station column it was scanned in
                                    // so find the frame in the wrong column and then remove it
                                    // add it to the correct column using a temp ID so it doesn't clash.  Animate it as completed.
                                    // and add the frame to the 'next column' (which will be the rejected to or next in the process )

                                    // remove from 'wrong column'
                                    let frameFoundInColumn = findFrameInAnyStation(curStation, frameSerialId);
                                    let frameFound: FIP | undefined;
                                    if ( frameFoundInColumn ) {
                                         
                                          frameFoundInColumn.setHook( frames => {
                                                let otherFrames = frames.filter(frame => frame.serialId !== frameSerialId);
                                                frameFound = frames.find( f => f.serialId === frameSerialId );
                                                // remove from other column where it shouldn't be
                                                return [...otherFrames];
                                          })
                                          if ( frameFound ) {
                                                // add to the correct column where it was just finished
                                                let setCurColumnHook = getColumnHook(curStation);
                                                if ( setCurColumnHook) {
                                                      let copyFrame = JSON.parse(JSON.stringify(frameFound!));
                                                     // copyFrame.workState = FIPMessageTypes.FRAME_REJECTED_HERE;
                                                      copyFrame!.id = frameFound!.id + "."; // make it different
                                                      copyFrame!.workState = FIPMessageTypes.FRAME_END;
                                                      setCurColumnHook( frames => {
                                                            return [...frames, frameFound!].sort(sortFrameColumn);;
                                                      })
                                                }
                                                // add the frame to its destination column
                                                let setNextColumnHook = getColumnHook(nextStation);
                                                if ( setNextColumnHook ) {
                                                      setNextColumnHook( frames => {
                                                            frameFound!.workState = messageType === FIPMessageTypes.FRAME_REJECTED ? FIPMessageTypes.FRAME_REJECTED.valueOf() : undefined;
                                                            return [...frames, frameFound!].sort(sortFrameColumn);
                                                      })
                                                }

                                          }
                                        

                                    }
                                    if ( frameFound ) {
                                          let copyFrame = JSON.parse(JSON.stringify(frameFound!));
                                          // copyFrame.workState = FIPMessageTypes.FRAME_REJECTED_HERE;
                                           copyFrame!.id = frameFound!.id + "."; // make it different
                                           copyFrame!.workState = FIPMessageTypes.FRAME_END;
                                          return [...otherFrames, frameFound!].sort(sortFrameColumn);
                                    } else {
                                          return [...otherFrames];
                                    }
                              }
                         
                        })

                  }
            }

            const startWorkOnFrame = (curStation: number, frameSerialId: string, messageType: FIPMessageTypes) => {
                  // work is starting on this frame at this station
                  // need to take into account 
                  //  1) the frame may not be in the current station
                  //  2) the work on the frame may be canceled
                  //  3) 1 and 2 together means that a frame in the wrong station may get moved to the current station
                  //    to show work started, but if the work is canceled, it should go back to the original station (we aren't updating until work is finished)
                  //    so ... leave the frame in the original station _AND_ display it in the current station, then if canceled remove from current station only?

                  let frameFoundInColumn = findFrameInAnyStation(curStation, frameSerialId);
               

                  // if the frame is not found in a different column, want to remove it from there
                  // before adding it to the correct column
                  // but want to animate a copy of the original first
                  let frameFoundInCorrectColumn = frameFoundInColumn == null ? false : frameFoundInColumn!.stationId.valueOf() === curStation;

                  //if the frame is not in the curent column, it first needs to be removed from the 'other colummn' then added to the current column
                  let frameToFind: FIP | undefined  = undefined;

                 if ( !frameFoundInCorrectColumn && frameFoundInColumn) {
                        // need to remove the frame from its current column 
                        // then add it and modify it in the station it was just scanned at
                        frameFoundInColumn.setHook(frames => {

                              frameToFind = frames.find(f => f.serialId === frameSerialId);
                            
                              let otherFrames = frames.filter(frame => frame.serialId !== frameSerialId);
                              return otherFrames;
                        })
                  }
                  let setCurColumnHook = getColumnHook(curStation);
                     // the frame was found in the correct column
                  if ( setCurColumnHook) {

                        // need to add the frame (from the other column)  _or_ move it if its already in the correct column
                        setCurColumnHook(originalColumnFrames => {
                              if ( frameToFind  ) {
                                    frameToFind!.workState = messageType.valueOf();
                                    return [...originalColumnFrames, frameToFind!].sort(sortFrameColumn);
                              } else {
                                    const frameInCurrentColumn = originalColumnFrames.find(f => f.serialId === frameSerialId);
                                    const otherFrames = originalColumnFrames.filter(frame => frame.serialId !== frameSerialId);
                                    if (frameInCurrentColumn) {
                                          frameInCurrentColumn!.workState = messageType.valueOf();
                                          return [...otherFrames, frameInCurrentColumn!].sort(sortFrameColumn);
                                    } else {
                                          // this should never happen since it was in the correct column
                                          return otherFrames;
                                    }
                              }
                        })
                  } else  {
                        // frame not found -- ignore it
                  }
            }

            const voidFrame = ( curStationId: number, frameSerialId: string, ) => {
                  // voiding a frame.
                  let frameFoundInColumn = findFrameInAnyStation(curStationId, frameSerialId);
                  if ( frameFoundInColumn ) {
                        frameFoundInColumn.setHook( frames => {
                              let otherFrames = frames.filter( f => f.serialId !== frameSerialId);
                              let frame = frames.find( f=> f.serialId === frameSerialId );
                              if (frame) {
                                    frame!.isVoided = true;
                                    frame.workState = FIPMessageTypes.FRAME_VOIDED;
                                    return [...otherFrames, frame].sort(sortFrameColumn);
                              } else {
                                    return [...otherFrames];
                              }
                        })
                  }

            }

            const unvoidFrame = ( curStationId: number, frameSerialId: string, fip: FIP ) => {
                  //unvoiding a frame.  For good measure ensure it isn't already on the grid before re-adding it
                  // a person can void or unvoid a frame at any station, including the app's FIP screen, so no guarantee that curStationId is where the frame should be ..
                  // but at the same time, a voided frame in theory doesn't exist.. so impact is not important?
                  let frameFoundInColumn = findFrameInAnyStation(curStationId, frameSerialId);
                  if ( frameFoundInColumn ) {
                        frameFoundInColumn.setHook( frames => {
                              let otherFrames = frames.filter( f => f.serialId !== frameSerialId);
                              let frame = frames.find( f=> f.serialId === frameSerialId );
                              if (frame) {
                                    frame!.isVoided = false;
                                    frame.workState = FIPMessageTypes.FRAME_UNVOIDED;
                                    return [...otherFrames, frame].sort(sortFrameColumn);
                              } else {
                                    fip.isVoided = false
                                    fip.workState = FIPMessageTypes.FRAME_UNVOIDED;
                                    return [...otherFrames, fip];
                              }
                        })
                  }
            }

            const addBatchItem = (msg: CuttingStationBatchItemMessage) => {

                  setActiveBatches(prevBatches => {
                        let batches = prevBatches.filter(batch => batch.batchId !== msg.batchId)
                        let modifiedBatch = prevBatches.find(batch => batch.batchId === msg.batchId);

                        if (modifiedBatch) {
                              let item = modifiedBatch.batchItems.find(items => items.productId === msg.batchItem!.productId)
                              if (item) {
                                    item.quantity = msg.batchItem!.quantity;
                              } else {
                                    // new item
                                    let newBatchItem = {} as BatchItem;
                                    newBatchItem.productId = msg.batchItem!.productId;
                                    newBatchItem.profile = msg.batchItem?.profile ?? "profile?"
                                    newBatchItem.quantity = msg.batchItem?.quantity ?? -99;
                                    newBatchItem.size = msg.batchItem!.size ?? "size?";
                                    newBatchItem.style = msg.batchItem?.style ?? "style?";
                                    newBatchItem.year = msg.batchItem?.year ?? -2000;
                                    modifiedBatch.batchItems.push(newBatchItem)

                              }

                              batches.push(modifiedBatch)
                              return batches
                        } else {
                              return [...prevBatches]
                        }
                  })
            }


            //setMessages(prevMessages => [...prevMessages, msg]);


            socket.on('connect', () => {
                  setIsConnected(true);
                  /*
                  const engine = socket.io.engine;
                  engine.on("packet", ({ type, data }) => {
                     // called for each packet received
                   });
                   */
            });

            socket.on('disconnect', () => {
                  setIsConnected(false);
            });

            socket.on('pong', () => {
                  setLastPong(new Date().toISOString());
            });

            socket.on(SOCKET_CHANNELS.CUTTING_PREMIERE.valueOf(), (message) => {
                  let msg = message as MessageBase;

                  if (msg.messageType === MessageTypes.BATCH_CRUD) {
                        let m = msg as CuttingStationBatchMessage;
                        //TODO need to handle recuts??
                        if (m.batchState === BatchState.CREATING) {
                              // at creating the batch has an id and a user but no other info is yet input
                              let newBatch = {} as ActiveBatch;
                              newBatch.batchId = m.batchId;
                              newBatch.cutBy = "TODO"
                              newBatch.frameStyle = m.batchDetails?.frameStyle ?? ""
                              newBatch.batchItems = [];
                              newBatch.image = m.batchDetails?.image ?? ""
                              setActiveBatches(prevBatches => {
                                    let batches = activeBatches.filter(batch => batch.batchId !== m.batchId)
                                    return [...prevBatches, newBatch];
                              })

                        } else if (m.batchState === BatchState.STARTED) {
                              //batch went from created to -> started so it now has a style, image 
                              // decided not to show the 'created' state since it has limited useful info and rather add it here
                              // the started state can now therefore be either a creation or an update (ie. if use changes frame style)
                              // this however never 'adds batch items' so no need to worry about that
                              setActiveBatches(prevBatches => {
                                    let batches = prevBatches.filter(batch => batch.batchId !== m.batchId)
                                    let existingBatch = prevBatches.find(batch => batch.batchId === m.batchId)

                                    if (existingBatch) {
                                          existingBatch.frameStyle = m.batchDetails?.frameStyle ?? "style?"
                                          existingBatch.image = m.batchDetails?.image ?? "image?"
                                          return [...batches, existingBatch];
                                    } else {
                                          let newBatch = {} as ActiveBatch;

                                          newBatch.batchId = m.batchId;
                                          newBatch.cutBy = m.batchDetails?.createdBy ?? "user?"
                                          newBatch.timeStarted = m.batchDetails?.createdDateTime ?? "time?"
                                          newBatch.frameStyle = m.batchDetails?.frameStyle ?? "style?"
                                          newBatch.batchItems = [];
                                          newBatch.image = m.batchDetails?.image ?? "image?"

                                          return [...batches, newBatch]
                                    }
                              });

                        } else if (m.batchState === BatchState.ENDED) {

                              setActiveBatches(prevBatches => {
                                    let batches = activeBatches.filter(batch => batch.batchId !== m.batchId)
                                    return batches;
                              })

                        } else if (m.batchState === BatchState.CANCELED) {
                              setActiveBatches(prevBatches => {
                                    let batches = activeBatches.filter(batch => batch.batchId !== m.batchId)
                                    return batches;
                              })
                        }

                  } else if (msg.messageType === MessageTypes.ADD_BATCH_ITEM) {
                        addBatchItem(msg as CuttingStationBatchItemMessage);
                  } else if (msg.messageType === MessageTypes.DELETE_BATCH_ITEM) {
                        setActiveBatches(prevBatches => {
                              let m = msg as CuttingStationBatchItemMessage;
                              let batches = prevBatches.filter(batch => batch.batchId !== m.batchId)
                              let modifiedBatch = prevBatches.find(batch => batch.batchId === m.batchId);
                              if (modifiedBatch) {
                                    if (m.batchItem && m.batchItem!.quantity === 0) {
                                          // no items left of this year/size so remove entry
                                          const newItems = modifiedBatch.batchItems.filter(i => i.productId !== m.batchItem!.productId);
                                          modifiedBatch.batchItems = newItems;
                                          return [...batches, modifiedBatch];
                                    } else {
                                          // set the quantity of an existing entry
                                          let item = modifiedBatch.batchItems.find(items => items.productId === m.batchItem!.productId)
                                          if (item) {
                                                item.quantity = m.batchItem!.quantity;
                                          }
                                          batches.push(modifiedBatch)
                                          return batches
                                    }

                              } else {
                                    return [...prevBatches]
                              }
                        })
                  }

            });

            socket.on(SOCKET_CHANNELS.FIP.valueOf(), (message) => {

                  console.log('received' + message)
                  // move frames from one station to another as events are recorded
                  let m = message as FIPMessage;
                  const curStation = m.curStation;
                  const nextStation = m.nextStation; // could be null
                  const frameSerialId = m.frameSerialId;
                  let curFrame = null;
                  let columnHook = null;

                  // if a frame is started, add it in the appropriate column
                  // if it is finished, remove its current column and move it to its destination
                  // if the voided - remove it from display
                  // if it is unvoided - add it back to the display in the appropriate column
                  // if it is rejected - need to show rejection message at the station it is at ... and once that animation finishes... move it to its final destination
                  if (m.messageType === FIPMessageTypes.FRAME_END || m.messageType === FIPMessageTypes.FRAME_VOIDED || m.messageType === FIPMessageTypes.FRAME_REJECTED) {

                        // remove the frame (and animate) from the column it is currently in 
                        // note that it is possible it is elsewhere if the process is out of order on the floor
                        
                        endWorkOnFrame( m.curStation, m.nextStation!, m.frameSerialId, m.messageType );


                  } else if (m.messageType === FIPMessageTypes.FRAME_START || m.messageType === FIPMessageTypes.FRAME_UNVOIDED ) {

                        startWorkOnFrame(m.curStation, m.frameSerialId, m.messageType );
                  } else if  ( m.messageType === FIPMessageTypes.FRAME_UNVOIDED ) { 
                        unvoidFrame( m.curStation, m.frameSerialId, m.fip!)
                  } else if  ( m.messageType === FIPMessageTypes.FRAME_VOIDED ) { 
                        voidFrame( m.curStation, m.frameSerialId )
                  }

            });

            socket.on('batch_start', (message) => {
                  console.log('received' + message)
            });

            socket.on('batch_end', (message) => {
                  console.log('received' + message)
            });

            return () => {
                  socket.off('connect');
                  socket.off('disconnect');
                  socket.off('pong');
                  socket.off('fip');
            };
      }, [cutting, boring, coloring, pinsAndMagnets, qc, boxing, shipping, milling, finalPrep]);

      React.useEffect(() => {
            axios.get(`http://${ip}:3000/api/frames-in-progress/${frameType}`).then((response) => {


                  if (frameType === FrameTypesEnum.premiere) {
                        setCutting(response.data.filter((i: FIP) => i.curStationId === StationsEnum.cutting).sort(sortFrameColumn));
                        setBoring(response.data.filter((i: FIP) => i.curStationId === StationsEnum.boring).sort(sortFrameColumn));
                        setColoring(response.data.filter((i: FIP) => i.curStationId === StationsEnum.coloring).sort(sortFrameColumn));
                        setPingsAndMagnets(response.data.filter((i: FIP) => i.curStationId === StationsEnum.pinsAndMagnets).sort(sortFrameColumn));
                        setQC(response.data.filter((i: FIP) => i.curStationId === StationsEnum.qualityControl).sort(sortFrameColumn));
                        setBoxing(response.data.filter((i: FIP) => i.curStationId === StationsEnum.boxing).sort(sortFrameColumn));
                        setShipping(response.data.filter((i: FIP) => i.curStationId === StationsEnum.premiereAwaitingShipping).sort(sortFrameColumn));
                  } else {
                        setCutting(response.data.filter((i: FIP) => i.curStationId === StationsEnum.alloyCutting).sort(sortFrameColumn));
                        setMilling(response.data.filter((i: FIP) => i.curStationId === StationsEnum.milling).sort(sortFrameColumn));
                        setFinalPrep(response.data.filter((i: FIP) => i.curStationId === StationsEnum.finalPrep).sort(sortFrameColumn));
                        setBoxing(response.data.filter((i: FIP) => i.curStationId === StationsEnum.boxingAlloy).sort(sortFrameColumn));
                  }

                  axios.get(`http://${ip}:3000/api/frames-in-progress/active-batches/${frameType}`).then((response) => {
                        setActiveBatches(prevBatches => response.data.activeBatches);
                  });

            });

      }, []);

      function sortFrameColumn(a: FIP, b: FIP) {
            ///return negative if the first item is smaller; positive if it it's larger, or zero if they're equal.
            if ( a.workState || b.workState  ) {
                  if ( a.workState && b.workState && a.workState == b.workState){
                        return 0;
                  } else if ( a.workState && b.workState == undefined ) {
                        return -1;
                  } else if ( a.workState == undefined && b.workState ) {
                        return 1;
                  } else if ( a.workState && b.workState && a.workState !== b.workState){ 
                        if ( a.workState == "STARTED" ){
                              return -1
                        } else {
                              return -1;
                        }
                  }
            }

            if (a.orderNumber && !b.orderNumber) {
                  return -1
            }

            if (!a.orderNumber && b.orderNumber) {
                  return 1
            }
            if (a.orderNumber && b.orderNumber) {
                  if (a.orderPriorityId === "2") {
                        return 1
                  } else {
                        return -1
                  }
            }

            // if both entries have an order bubble up by priority

            // otherwise sort baseed on creation date, oldest to newest
            //                 20     18 = 2 = a after b 
            return Date.parse(a.createdDateLocal) - Date.parse(b.createdDateLocal);

      }

      //  if (!framesInProgress) return null;
/*

                  */
      return (
            <div className="App">
 {isConnected === false && 
                        <div id="connection-status"> 
                              CONNECTION LOST ... attemping reconnect...
                        </div>
                  }
                  <div id="frame-columns">
                        {frameType === FrameTypesEnum.premiere && <>
                              <div>
                                    <ActiveBatches batches={activeBatches} />
                                    <StationColumn title={"Cutting"} framesInProgress={cutting} />
                              </div>
                              <StationColumn title={"Boring"} framesInProgress={boring} />
                              <StationColumn title={"Coloring"} framesInProgress={coloring} />
                              <StationColumn title={"Pins & Magnets"} framesInProgress={pinsAndMagnets} />
                              <StationColumn title={"QC"} framesInProgress={qc} />
                              <StationColumn title={"Boxing"} framesInProgress={boxing} />
                              <StationColumn title={"Tickets to Ship"} framesInProgress={shipping} />
                        </>
                        }
                        {frameType === FrameTypesEnum.alloy && <>
                              <StationColumn title={"Cutting"} framesInProgress={cutting} />
                              <StationColumn title={"Milling"} framesInProgress={milling} />
                              <StationColumn title={"FinalPrep"} framesInProgress={finalPrep} />
                              <StationColumn title={"Boxing"} framesInProgress={boxing} />
                        </>
                        }
                  </div>
            </div>

      )

}

export default App;
import React from "react"
import Config from "./config";
import FIP from "./models/fip";
import { Slide, FadeOut } from 'react-animated-components'
import { FIPMessageTypes } from "./messages/fip-messages";

interface IFIPTileInnerProps {
      fip: FIP;
      currentWork: any
}

const FIPTileInner = (props: IFIPTileInnerProps) => {
      function getAge(fip: FIP) {
            var made = Date.parse( fip.createdDateLocal );
            let now = new Date();
            let difference = now.getTime() - made;
            let daysOld = Math.ceil(difference / (1000 * 3600 * 24));
            let postfix = daysOld > 1 ? " days" : " day"
            return daysOld + postfix;
      }

      function ticketData() {
            return {
                  frameurl: `${Config.baseUrl}/img/moulding-label/img/${props.fip.image.replace( '_SUSTAINABLE', '')}.jpg`,
                  serialId: props.fip.serialId, 
                  age: 'todo',
                  framestyle: `${props.fip.name} ${props.fip.size}" - ${props.fip.year}`,
                  size: props.fip.size,
                  year: props.fip.year,
                  orderNumber: props.fip.ticket != null ? props.fip.ticket.orderNumber : "",
                  orderPriority:  props.fip.ticket != null ? ( props.fip.ticket.shippingPrioirtyDescription != null ? props.fip.ticket.shippingPrioirtyDescription : "") : "",
                  orderSource: props.fip.ticket != null ? ( props.fip.ticket.orderSourceDescription != null ? props.fip.ticket.orderSourceDescription : "" ) : "",
                  serviceLevel:  props.fip.ticket != null ? ( props.fip.ticket.shippingServiceLevelDescription != null ? props.fip.ticket.shippingServiceLevelDescription : "") : "",
                  shipByDate: props.fip.ticket != null ? ( props.fip.ticket.shipByDate  != null ? props.fip.ticket.shipByDate : "" ) : "",

            }
      }

      return (

                  <div className={`frame ${props.fip.orderPriorityId === "1" ? "priority-low" : props.fip.orderPriorityId === "2" ? "priority-high" : "priority-none"} ${props.fip.workState === FIPMessageTypes.FRAME_START.valueOf() ? "state-started" : ""} ${props.fip.workState == FIPMessageTypes.FRAME_END.valueOf()? "state-completed" : ""}`}>
                        <div className={`frame-year-border year${props.fip.year} `}>
                        </div>
                        <div className="frame-image">
                              <div className={ `frame-style-color  size-${props.fip.size}` }>
                                    <img src={Config.baseUrl + '/img/moulding-label/img/' + props.fip.image.replace( '_SUSTAINABLE', '') + '.jpg' } alt="frame" />
                              </div>
                        </div>

                        <div className="frame-details">
                              <div className='top-row'>
                                    <div className='serial'>
                                          {props.fip.serialId}
                                    </div>
                                    <div className='age'>
                                          { getAge(props.fip)}
                                    </div>
                              </div>
                              <div className='frame-style'>
                                    {props.fip.name} {props.fip.size} - {props.fip.year}
                              </div>
                              {props.fip.isReturned && 
                                    <div className='other-properties'>
                                          <div>{props.fip.isReturned === true ? "RTN" : ""}</div>
                                          <div>{props.fip.returnDate}</div>
                                          <div>{props.fip.isRecut === true ? "RCT" : ""}</div>
                                    </div>
                              }
                              {props.fip.orderNumber &&

                                    <div className='ticket'>
                                          <div>{props.fip.orderNumber}</div>
                                          <div>{props.fip.shipByDate}</div>
                                          <div>{props.fip.orderSourceDescription}</div>
                                          <div>{props.fip.shippingServiceLevelDescription}</div>
                                    </div>
                              }
                              {props.fip.workState && 
                                    <div className={ `work-state ${props.fip.workState.valueOf().toLowerCase()}` }>
                                          {props.fip.workState}
                                    </div>
                              }
                        </div>
                  </div>

      );


}

export default FIPTileInner;

import React from "react";
import FIPTile from "./fip-tile";
import FIP from "./models/fip";


interface IStationColumnProps {
      title: string;
      framesInProgress: FIP[];
}

const StationColumn = ( props: IStationColumnProps  ) =>  {

      return (
            <div className="frame-column">
                  <h1 className="column-title">{props.title}- {props.framesInProgress ? props.framesInProgress.length : 0}</h1>
                  <div className="frame-list">
                        { props.framesInProgress.map ( ( fip: FIP ) => {
                              return <FIPTile key={fip.serialId } fip={fip} currentWork={null} />
                              })
                        }

                  </div>
            </div>
      )
}

export default StationColumn;
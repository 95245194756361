
import React from "react";
import Config from "./config";

import { ActiveBatch } from "./models/active-batches/active-batch";
import { BatchItem } from "./models/active-batches/batch-item";


interface IActiveBatchesProps {
      batches: ActiveBatch[]
}

const ActiveBatches = ( props: IActiveBatchesProps  ) =>  {

      return (
            <div className="active-batches">
                  <h1 className="column-title">Currently Cutting</h1>
                  { props.batches.map ( ( batch: ActiveBatch ) => {
                        return <div className="batch">
                                    <div className='batch-image'>
                                           <img src={Config.baseUrl + '/img/moulding-label/img/' + batch.image.replace( '_SUSTAINABLE', '') + '.jpg' } alt="frame" />
                                           <div className='flex-column'>
                                                 <div className='frame-type'> {batch.frameStyle}</div>
                                                 <div>By: {batch.cutBy}  </div>
                                                 <div> Started: {batch.timeStarted} </div>
                                           </div>
                                     </div>
                                    <div className="batch-items">
                                          { batch.batchItems.map( ( item: BatchItem ) => {
                                                
                                                return  <div className={ `batch-item size-${item.size}`}>
                                                            <span className={ `year${item.year}`}>{item.year}</span>
                                                            {item.size}"  QTY: {item.quantity}
                                                         </div>
                                          })}
                                    </div>
                        </div>
                  }) }
            </div>
      )
}

export default ActiveBatches;
import { BatchItem } from "../models/active-batches/batch-item";

export enum BatchState  {    
      STARTED = 'STARTED',
      TEMP_STARTED = 'TEMP_STARTED',
      ENDED = 'ENDED',
     // TEMP_ENDED = 'TEMP_ENDED', not used.. used ENDED instead.
      CANCELED = 'CANCELED',
      TEMP_CANCELED = 'TEMP_CANCELED',
      CREATING = 'CREATING'
  }

  export enum MessageTypes {
      BATCH_CRUD = "BATCH_CRUD",
      ADD_BATCH_ITEM = "ADD_BATCH_ITEM",
      DELETE_BATCH_ITEM = "DELETE_BATCH_ITEM",   
}

export class MessageBase {
     messageType: string
     constructor( messageType: MessageTypes) {
           this.messageType = messageType.valueOf()
     }
}

export class CreatedBatchDetails {
      constructor( image: string, createdBy: string, frameStyleId: string, frameStyle: string, createdDateTime: string) {
            this.image = image;
            this.createdBy = createdBy
            this.frameStyleId = frameStyleId;
            this.frameStyle = frameStyle;
            this.createdDateTime = createdDateTime;
      }
      image: string;
      createdBy: string;
      frameStyleId: string;
      frameStyle: string;
      createdDateTime: string;
}

export class CuttingStationBatchMessage  extends MessageBase {
     
     constructor( batchState: BatchState, batchId: string, batchDetails?: CreatedBatchDetails) {
           super( MessageTypes.BATCH_CRUD )
           this.batchState = batchState.valueOf();
           this.batchId = batchId;
           this.batchDetails = batchDetails;
     }
     batchState: string
     batchId: string
     batchDetails?: CreatedBatchDetails

}



export class CuttingStationBatchItemMessage extends MessageBase {

      constructor( messageType: MessageTypes, batchId: string, productId: string, frameStyleId?: string, batchItem?: BatchItem) {
            super(messageType)
            this.batchId = batchId;
            this.productId = productId;
            this.frameStyleId = frameStyleId;
            this.batchItem = batchItem;
      }

      batchId: string;
      productId: string;
      frameStyleId?: string;
      batchItem?:BatchItem;
}